.org_main_container {
  padding: 30px;
  /* margin: 30px 30px 30px 30px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
  justify-content: center;
}

.org_card_main {
  width: 300px;
  height: 280px;
  text-align: center;
  border-radius: 10px;
  background-color: #dfe7f4;
}
.org_card_main:hover {
  box-shadow: 0 4px 20px 0 #00000019;
  cursor: pointer;
}
.org_card_each {
  padding: 100px 0;
  text-align: center;
  height: 100%;
}
.ord_card_img {
  margin-right: auto;
  margin-left: auto;
}
.text_container {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
}
.org_name {
  font-size: 18px;
  font-weight: 500;
}
.org_userName {
  font-size: 16px;
  font-weight: 500;
}
button.back-btn.Audit_back:hover {
  color: #418af3;
}
.text-white.toast-body {
  color: #000 !important;
  margin-top: 0px !important;
  background-color: #fff !important;
  font-size: 15px;
}
.org_main_container_err {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 45vh);
}
.no-data-available_err {
  margin: 0; /* Remove default margin */
  font-size: 19px;
  font-weight: 600;
}
